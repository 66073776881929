import httpService from "./HttpService";

export async function getBankImages(companyId, type) {
  const token = localStorage.getItem("auth");
  return httpService.get(`image-bank/${companyId}?type=${type}`, null, token);
}

export async function uploadBankImages(images) {
  const token = localStorage.getItem("auth");
  return httpService.post(`image-bank`, images, token);
}

export async function deleteBankImage(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`image-bank/${id}`, null, token);
}
