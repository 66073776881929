import {useMessageContext} from "../../../contexts/MessageContext";
import {Box, Button, Paper, TextField} from "@mui/material";
import {saveCompany} from "../../../services/CompanyService";

const CompanyAboutTab = ({ company, setCompany, setRefresh, setLoader}) => {

  const {notificationHandler} = useMessageContext();

  return (<Paper component={"form"}
                 onSubmit={(event => {
                   event.preventDefault();
                   setLoader(true);
                   const formData = new FormData(event.currentTarget);
                   const formJson = Object.fromEntries(formData.entries());

                   let body = {...company};
                   body.aboutProductInformation = formJson.aboutProductInformation;
                   body.aboutCompany = formJson.aboutCompany;
                   body.authorInformation = formJson.authorInformation;

                   saveCompany(body)
                       .then(() => setRefresh(prv => !prv))
                       .then(() => notificationHandler.success('Company Saved'))
                       .finally(() => setLoader(false))
                 })}
                 sx={{margin: "48px", padding: "24px"}} elevation={2} >
    <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
        <TextField
            multiline
            minRows={3}
            fullWidth
            margin="dense"
            id="aboutProductInformation"
            name="aboutProductInformation"
            label="About Product Information"
            type="text"
            defaultValue={company.aboutProductInformation}
        />
        <TextField
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="aboutCompany"
            name="aboutCompany"
            label="About Company"
            type="text"
            defaultValue={company.aboutCompany}
        />
        <TextField
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="authorInformation"
            name="authorInformation"
            label="Author Information"
            type="text"
            defaultValue={company.authorInformation}
        />
      <Box sx={{mt: "48px", display: "flex", alignItems: "center", justifyContent: "end"}}>
        <Button type="submit" variant={"contained"}>save</Button>
      </Box>
    </Box>
  </Paper>);
};

export default CompanyAboutTab;
