import {Box, Fab, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import AddIcon from "@mui/icons-material/Add";
import {deleteCompanyDocs, getCompanyDocs, saveCompanyDocs} from "../../../services/CompanyService";
import {useMessageContext} from "../../../contexts/MessageContext";
import Loader from "../../Loader";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const CompanyDocsTab = ({company, setLoader}) => {

  const {notificationHandler} = useMessageContext();

  const [docs, setDocs] = useState([]);

  const [reload, setReload] = useState(false);

  const inputFileRef = useRef( null );

  useEffect(() => {
    setLoader(true);
    getCompanyDocs(company.id).then(res => {
      setDocs(res.data);
    }).finally(() => setLoader(false));
  }, [reload]);

  const handleDelete = (id) => {
    setLoader(true);
    deleteCompanyDocs(id)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('Docs Deleted'))
        .finally(() => setLoader(false));
  }

  const onFileChange = async ( e ) => {
    /*Selected files data can be collected here.*/
    let body = await Promise.all([...e.target.files].map(async file => {
      return {
        name: file.name,
        base64: await convertBase64(file),
        companyId: company.id
      }
    }));

    setLoader(true);
    saveCompanyDocs(body)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('Docs Saved'))
        .finally(() => setLoader(false))
    // save Body to server
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  }

  const download = doc => {
    fetch(doc.base64, {
      method: "GET",
      mode: 'cors',           // Ensures CORS is handled
      cache: 'no-cache',      // Prevents caching issues
      headers: {'Access-Control-Request-Headers': '*', 'Vary': 'Origin',},
    })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", doc.name); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
  };

  return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell align={"right"}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((doc, index) => <TableRow key={index}>
              <TableCell>{doc.name}</TableCell>
              <TableCell>{moment(doc.createDate).format("DD/MM/YY HH:mm:ss")}</TableCell>
              <TableCell align="right">
                <Box sx={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                <Box sx={{cursor: "pointer"}} onClick={() => handleDelete(doc.id)}>
                  <DeleteIcon/>
                </Box>
                <Box onClick={() => download(doc)} sx={{cursor: "pointer", ml: "12px"}}>
                  <CloudDownloadIcon/>
                </Box>
                </Box>
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
        <Fab sx={{position: 'fixed', top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',}} color="primary" aria-label="add" onClick={onBtnClick} >
          <AddIcon />
          <input
              type="file"
              hidden
              multiple
              ref={inputFileRef}
              onChange={onFileChange}
          />
        </Fab>
      </Paper>);
};

export default CompanyDocsTab;
