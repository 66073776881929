import {useMessageContext} from "../../../contexts/MessageContext";
import {Box, Button, Paper, TextField} from "@mui/material";
import {saveCompany} from "../../../services/CompanyService";

const CompanyCreateAboutTab = ({ company, setField}) => {


  return (
    <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
        <TextField
            multiline
            minRows={3}
            fullWidth
            margin="dense"
            id="aboutProductInformation"
            name="aboutProductInformation"
            label="About Product Information"
            type="text"
            value={company.aboutProductInformation}
            onChange={(e) => setField("aboutProductInformation", e.target.value)}
        />
        <TextField
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="aboutCompany"
            name="aboutCompany"
            label="About Company"
            type="text"
            onChange={(e) => setField("aboutCompany", e.target.value)}
            value={company.aboutCompany}
        />
        <TextField
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="authorInformation"
            name="authorInformation"
            label="Author Information"
            type="text"
            onChange={(e) => setField("authorInformation", e.target.value)}
            value={company.authorInformation}
        />
    </Box>
  );
};

export default CompanyCreateAboutTab;
