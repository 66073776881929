import {Box, Grid, Paper, styled, Typography} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalenderDay from "../componenets/CalenderDay";
import {useEffect, useState} from "react";
import {getCalender} from "../services/CalenderService";

const CalenderPage = ({company}) => {

  const [currentDate, setCurrentDate] = useState(new Date());

  const [calenderData, setCalenderData] = useState([]);

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  }

  useEffect(() => {
    let numberOfDaysInMonth = daysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    let fromDate = new Date(currentDate);
    let toDate = new Date(currentDate);

    getCalender(company.id, Date.parse(new Date(fromDate.setDate(-10))), Date.parse(new Date(toDate.setDate(numberOfDaysInMonth + 10))))
        .then(res => {
          setCalenderData(res.data);
        });
  }, [currentDate]);

    return (
        <>
          {currentDate &&
              <>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mr: "100px", mt: "28px", mb: "28px"}}>
            <KeyboardArrowLeftIcon
                onClick={() => setCurrentDate(prv => {
                  return new Date(prv.getFullYear(), prv.getMonth() - 1);
                })}
                sx={{width: "30px", cursor: "pointer", height: "30px", "&:hover": {
              backgroundColor: "rgba(52,68,52,0.1)", borderRadius: "24px"}}}/>
          <Typography sx={{fontSize: "20px", mr: "20px", ml: "20px"}}>{currentDate.toLocaleString("en-US", { month: "long", year: "numeric"})}</Typography>
            <KeyboardArrowRightIcon
                onClick={() => setCurrentDate(prv => {
                  return new Date(prv.getFullYear(), prv.getMonth() + 1);
                })}
                sx={{width: "30px", cursor: "pointer", height: "30px", "&:hover": {
                backgroundColor: "rgba(52,68,52,0.1)", borderRadius: "24px"}}}/>
          </Box>
        <Grid sx={{mt: "10px"}} container spacing={0} columns={14}>
          {Array.from(Array(35), (e, i) => {
              return <CalenderDay calenderData={calenderData} date={new Date(currentDate.getFullYear(), currentDate.getMonth(), i+1)}/>
          })}
        </Grid>
              </>}
        </>
    )
};

export default CalenderPage;
