import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, InputLabel, MenuItem, Select,
  TextField
} from "@mui/material";
import {useState} from "react";

const AddEditImageTitle = ({title, open, handleClose, onSubmit, isEdit}) => {
  const [statusSelected, setStatusSelected] = useState(title.status || "AVAILABLE");

  const statues = ["AVAILABLE",
    "IN_FEED"]

  return (<Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());

          if (isEdit) {
            onSubmit(formJson.title, statusSelected, title, true);
          } else {

          let titles = formJson.title.split("\n");
          onSubmit(titles, false);
        }
        },
      }}
  >
    <DialogTitle>Title</DialogTitle>
    <DialogContent>
      <TextField
          autoFocus
          required
          multiline
          defaultValue={title?.title || ""}
          sx={{width: "250px"}}
          margin="dense"
          id="title"
          name="title"
          label="title"
          type="text"
          variant="standard"
      />
    </DialogContent>
    <DialogContent>
      <FormControl sx={{width: "300px"}}>
        <InputLabel id="demo-simple-select-label">status</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="status"
            value={statusSelected}
            onChange={(e) => setStatusSelected(e.target.value)}
        >

          {statues.map((status, i) => {
            return <MenuItem value={status}>{status}</MenuItem>
          })}
        </Select>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button color={"error"} onClick={handleClose}>Cancel</Button>
      <Button variant={"contained"} type="submit">Save</Button>
    </DialogActions>
  </Dialog>);
};

export default AddEditImageTitle;
