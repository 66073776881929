import {Box, Button, Chip, Divider, Paper, TextField} from "@mui/material";
import {saveCompany} from "../../../services/CompanyService";
import {Autocomplete} from "@mui/lab";
import {useMessageContext} from "../../../contexts/MessageContext";

const CompanyInfoPage = ({company, setCompany, setLoader, setRefresh, setDeleteCompanyDialog}) => {

  const {notificationHandler} = useMessageContext();

  const updateName = (value) => {
    setCompany(prv => {
      return {...prv, name : value}
    })
  }

  const updateInfo = (value) => {
    setCompany(prv => {
      return {...prv, info : value}
    })
  }

  const updateIndustry = (value) => {
    setCompany(prv => {
      return {...prv, industry : value}
    })
  }

  const updateCompanyDifferentiators = (value) => {
    setCompany(prv => {
      return {...prv, companyDifferentiators : value}
    })
  }

  const updateCompanyPersonasProduct = (value) => {
    setCompany(prv => {
      return {...prv, personasProduct : value}
    })
  }

  const updateCompanyPersonasSeller = (value) => {
    setCompany(prv => {
      return {...prv, personasSeller : value}
    })
  }

  const updateCompanyCompanyStandards = (value) => {
    setCompany(prv => {
      return {...prv, companyStandards : value}
    })
  }

  const updateCompanySolution = (value) => {
    setCompany(prv => {
      return {...prv, companySolution : value}
    })
  }

  const updateCompanyCategory = (value) => {
    setCompany(prv => {
      return {...prv, companyCategory : value}
    })
  }

  const updateValueProposition = (value) => {
    setCompany(prv => {
      return {...prv, valueProposition : value}
    })
  }

  return (<Paper component={"form"}
                     onSubmit={(event => {
                       event.preventDefault();
                       setLoader(true);
                       // const formData = new FormData(event.currentTarget);
                       // const formJson = Object.fromEntries(formData.entries());
                       saveCompany(company)
                           .then(() => setRefresh(prv => !prv))
                           .then(() => notificationHandler.success('Company Saved'))
                           .finally(() => setLoader(false))
                     })}
                     sx={{margin: "48px", padding: "24px"}} elevation={2} >
    <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <TextField
          disabled
          sx={{width: "10%"}}
          margin="dense"
          id="id"
          name="id"
          label="Id"
          type="number"
          value={company.id}
      />
      <TextField
          required
          sx={{width: "40%"}}
          margin="dense"
          id="name"
          name="name"
          label="Company Name"
          type="text"
          onChange={(e) => updateName(e.target.value)}
          value={company.name}
      />
        <TextField
            sx={{width: "40%"}}
            margin="dense"
            id="industry"
            name="industry"
            label="Company Industry"
            type="text"
            onChange={(e) => updateIndustry(e.target.value)}
            value={company.industry}
        />
      </Box>

      <TextField
          required
          multiline
          fullWidth
          minRows={3}
          sx={{width: "100%"}}
          margin="dense"
          id="info"
          name="info"
          label="Company Information"
          type="text"
          onChange={(e) => updateInfo(e.target.value)}
          value={company.info}
      />
      <TextField
          multiline
          fullWidth
          minRows={3}
          sx={{width: "100%"}}
          margin="dense"
          id="company Solution"
          name="Company Solution"
          label="Company Solution"
          type="text"
          onChange={(e) => updateCompanySolution(e.target.value)}
          value={company.companySolution}
      />
      <TextField
          margin="dense"
          fullWidth
          id="companyVertical"
          name="Company Vertical"
          label="Company Vertical"
          type="text"
          onChange={(e) => updateCompanyCategory(e.target.value)}
          value={company.companyCategory}
      />

      <TextField
          margin="dense"
          fullWidth
          id="CompanyValueProposition"
          name="Company Value Proposition"
          label="Company Value Proposition"
          type="text"
          onChange={(e) => updateValueProposition(e.target.value)}
          value={company.valueProposition}
      />
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
        <Autocomplete
            multiple
            id="tags-filled"
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.personasProduct}
            onChange={(event, newValue) => {
              updateCompanyPersonasProduct(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} aria-multiline variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Persona Users"
                />
            )}
        />
        <Autocomplete
            multiple
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            id="tags-filled"
            options={[].map((option) => option.title)}
            freeSolo
            value={company.personasSeller}
            onChange={(event, newValue) => {
              updateCompanyPersonasSeller(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} aria-multiline variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Persona Buyer"
                />
            )}
        />
        <Autocomplete
            multiple
            id="tags-filled"
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.companyDifferentiators}
            onChange={(event, newValue) => {
              updateCompanyDifferentiators(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Company Differentiators"
                />
            )}
        />
        <Autocomplete
            multiple
            id="tags-filled"
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.companyStandards}
            onChange={(event, newValue) => {
              updateCompanyCompanyStandards(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Customer Standards"
                />
            )}
        />
      </Box>
      <Box sx={{mt: "48px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Button onClick={() => setDeleteCompanyDialog(true)} sx={{mr: "12px"}} color={"error"}>Delete Company</Button>
        <Button type="submit" variant={"contained"}>save</Button>
      </Box>
    </Box>
  </Paper>);
}
export default CompanyInfoPage;
