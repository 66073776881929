import httpService from "./HttpService";

export async function getSeo(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`seo/${companyId}`, null, token);
}

export async function saveSeo(data, companyId) {
  const token = localStorage.getItem("auth");
  return httpService.post(`seo/${companyId}`, data, token);
}

export async function deleteSeo(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`seo/${companyId}`, null, token);
}
