import {Box, Button, Chip, Paper, Switch, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {deleteSeo, getSeo, saveSeo} from "../../services/SeoService";
import {Autocomplete} from "@mui/lab";

const CompanySEO = ({company}) => {
  const [seo, setSeo] = useState({});
  const [loading, setLoading] = useState(true);
  const [categoryIds, setCategoryIds] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
      getSeo(company.id)
          .then(res => {
            setSeo(res.data)
            setCategoryIds(res.data.categoryIds || [])
            setIsEnabled(res.data.enabled || false)
          })
          .finally(() => setLoading(false))


  }, [company, reload]);


  return <Paper>
    {!loading &&
    <Box sx={{p: 2}} component={"form"}
         onReset={(event => {
            event.preventDefault();
            setCategoryIds([]);
            setIsEnabled(false);
            deleteSeo(company.id).then(r => setReload(prv => !prv))
           event.currentTarget.wrapUp.value = "0";
           event.currentTarget.nextStep.value = "0";
         })}

         onSubmit={(event => {
           event.preventDefault();
           const formData = new FormData(event.currentTarget);
           const formJson = Object.fromEntries(formData.entries());
           console.log(formJson);

           saveSeo({
             wrapUp: formJson.wrapUp,
              nextStep: formJson.nextStep,
              categoryIds: categoryIds,
              enabled: isEnabled
           }, company.id)
               .then(() => setReload(prv => !prv))
         })}
    >
      <Typography variant={"h6"}>SEO</Typography>
      <Typography >Start Date: {seo.startDate || "None"}</Typography>
      <br/>
      <TextField
          autoFocus
          defaultValue={seo.wrapUp}
          sx={{width: "250px"}}
          margin="dense"
          id="wrapUp"
          name="wrapUp"
          label="Number of Articles first 30 days"
          type="number"
          variant="standard"
      />
      <br/>
      <TextField
          autoFocus
          defaultValue={seo.nextStep}
          sx={{width: "250px"}}
          margin="dense"
          id="nextStep"
          name="nextStep"
          label="Number of Articles after 30 days"
          type="number"
          variant="standard"
      />
      <br/>
      <Autocomplete
          multiple
          id="tags-filled"
          sx={{mt: "8px", width: "250px"}}
          options={[].map((option) => option.title)}
          freeSolo
          value={categoryIds}
          onChange={(event, newValue) => {
            setCategoryIds(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip sx={{ height: 'auto','& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },
                  }} aria-multiline variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  variant={"standard"}
                  {...params}
                  label="Category Ids"
              />
          )}
      />

      <Box sx={{mt: "24px", display: "flex",  alignItems: "center"}}>
        <Typography>On / Off</Typography>
      <Switch id={"switch"} inputProps={{'aria-label': 'Switch demo'}} checked={isEnabled} onChange={() => setIsEnabled(prv => !prv)}/>
      </Box>
      <br/>
      <Box sx={{display: "flex", justifyContent: "end"}}>
      <Button variant={"text"} type="reset" color={"error"}>Clear</Button>
      <Button sx={{ml: "10px"}} variant={"contained"} type="submit" >Save</Button>
      </Box>
    </Box>}
  </Paper>
};

export default CompanySEO;
