import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useAuth} from "../contexts/AuthContext";

export const AuthGuard = ({ children }) => {
  const {loggedIn} = useAuth();
  const location = useLocation();
  if (!loggedIn && location.pathname !== "/login") {
    return <Navigate to="/login" replace />
  } else if (!loggedIn)
    return;

  return children
}
