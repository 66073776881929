import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {saveLinkedinIntegration} from "../services/IntegrationService";

const Linkdin = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("__firebase_request_key")

  const base64 = require('base-64');

  useEffect(() => {
    console.log("code:", searchParams.get("code"));
    const data = searchParams.get("data");
    const decodedData = base64.decode(data);
    const jsonData = JSON.parse(decodedData)

    saveLinkedinIntegration(jsonData.companyId,
        searchParams.get("code"),
        jsonData.orgId,
        `${window.location.origin}/app/linkdin?data=${data}`)
        .then(_ => window.close())


  }, []);
};

export default Linkdin;
