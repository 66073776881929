import {useMessageContext} from "../../../contexts/MessageContext";
import {useEffect, useState} from "react";
import {getCompanyKeywords, saveCompanyKeywords} from "../../../services/CompanyService";
import {Box, Button, Chip, Paper, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";

const CompanyCreateKeywordsTag = ({company, setField}) => {

  return (
    <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.primaryKeywords}
          onChange={(event, newValue) => {
            setField("primaryKeywords", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Primary Keywords"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.secondaryKeywords}
          onChange={(event, newValue) => {
            setField("secondaryKeywords", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Secondary Keywords"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.industryKeywords}
          onChange={(event, newValue) => {
            setField("industryKeywords", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Industry Keywords"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.productKeywords}
          onChange={(event, newValue) => {
            setField("productKeywords", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Product Keywords"
              />
          )}
      />
    </Box>
 );
};

export default CompanyCreateKeywordsTag;
