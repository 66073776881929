import {useMessageContext} from "../../../contexts/MessageContext";
import {useEffect, useState} from "react";
import {getCompanyKeywords, saveCompanyKeywords} from "../../../services/CompanyService";
import {Box, Button, Chip, Paper, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";

const CompanyKeywordsTag = ({company, setLoader}) => {

  const {notificationHandler} = useMessageContext();

  const [companyKeywords, setCompanyKeywords] = useState();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoader(true);
    getCompanyKeywords(company.id)
        .then(res => {
          setCompanyKeywords(res.data);
        }).then(() => setLoader(false))
  }, [reload]);


  const updatePrimaryKeywords = (primaryKeywords) => {
    setCompanyKeywords(prv => {
      return {...prv, primaryKeywords}
    })
  }

  const updateSecondaryKeywords = (secondaryKeywords) => {
    setCompanyKeywords(prv => {
      return {...prv, secondaryKeywords}
    })
  }

  const updateIndustryKeywords = (industryKeywords) => {
    setCompanyKeywords(prv => {
      return {...prv, industryKeywords}
    })
  }

  const updateProductKeywords = (productKeywords) => {
    setCompanyKeywords(prv => {
      return {...prv, productKeywords}
    })
  }

  return (<Paper component={"form"}
                 onSubmit={(event => {
                   event.preventDefault();
                   setLoader(true);
                   // const formData = new FormData(event.currentTarget);
                   // const formJson = Object.fromEntries(formData.entries());
                   companyKeywords.companyId = company.id;
                   saveCompanyKeywords(companyKeywords)
                       .then(() => setReload(prv => !prv))
                       .then(() => notificationHandler.success('Company Saved'))
                       .finally(() => setLoader(false))
                 })}
                 sx={{margin: "48px", padding: "24px"}} elevation={2} >
    {companyKeywords && <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyKeywords.primaryKeywords}
          onChange={(event, newValue) => {
            updatePrimaryKeywords(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Primary Keywords"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyKeywords.secondaryKeywords}
          onChange={(event, newValue) => {
            updateSecondaryKeywords(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Secondary Keywords"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyKeywords.industryKeywords}
          onChange={(event, newValue) => {
            updateIndustryKeywords(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Industry Keywords"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyKeywords.productKeywords}
          onChange={(event, newValue) => {
            updateProductKeywords(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Product Keywords"
              />
          )}
      />
      <Box sx={{mt: "48px", display: "flex", alignItems: "center", justifyContent: "end"}}>
        <Button type="submit" variant={"contained"}>save</Button>
      </Box>
    </Box>}
  </Paper>);
};

export default CompanyKeywordsTag;
