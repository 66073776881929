import {useEffect, useState} from "react";
import {deleteScheduler, getScheduler} from "../../services/SchedulerService";
import SchdulerSingleLine from "../SchdulerSingleLine";
import {Box, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SchdulerNewDialog from "../SchdulerNewDialog";

const CompanyScheduler = ({company}) => {

  const [schedulerData, setSchedulerData] = useState();

  const [refetch, setRefetch] = useState(false);

  const handleDelete = (data) => {
    deleteScheduler(data.id)
        .then(() => setRefetch(prv => !prv))
        .catch(e => console.log(e));
  };

  const [newEntry, setNewEntry] = useState({
    open: false,
    data: null
  });

  const handleEdit = (data) => {
    setNewEntry({
      open: true,
      data: data
    });
  }

  const handleOpen = () => {
    setNewEntry( {
      open: true,
    });
  }

  const handleClose = () => {
    setNewEntry( {
      open: false,
    });
  };

  useEffect(() => {
    getScheduler(company.id)
        .then(res => setSchedulerData(res.data))
        .catch(e => console.log(e));
  }, [newEntry, refetch]);

  return (
      <>
        <SchdulerNewDialog oldData={newEntry.data} open={newEntry.open} handleClose={handleClose} company={company}/>
          <Fab sx={{position: 'fixed', top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',}} color="primary" aria-label="add" onClick={handleOpen} >
            <AddIcon />
          </Fab>
        {schedulerData && <>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('SUNDAY'))} dayOfWeek={"Sunday"}/>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('MONDAY'))} dayOfWeek={"Monday"}/>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('TUESDAY'))} dayOfWeek={"Tuesday"}/>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('WEDNESDAY'))} dayOfWeek={"Wednesday"}/>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('THURSDAY'))} dayOfWeek={"Thursday"}/>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('FRIDAY'))} dayOfWeek={"Friday"}/>
          <SchdulerSingleLine handleDelete={handleDelete} handleEdit={handleEdit} schedulerData={schedulerData.filter(scheduler => scheduler.dayOfWeek.includes('SATURDAY'))} dayOfWeek={"Saturday"}/>
        </>}
      </>
  )
};

export default CompanyScheduler;
