import {Box, Tab, Tabs} from "@mui/material";
import {useEffect, useState} from "react";
import ImageTemplates from "./ImageTemplates";
import ImageTitles from "./ImageTitles";
import ImageBank from "./ImageBank";
import {getCompanySettings} from "../services/CompanyService";

const ImageTemplatesTab = ({company}) => {

  const [currentTab, setCurrentTab] = useState(0);

  const [companySettings, setCompanySettings] = useState({});

  const handleChange = (event, val) => {
    setCurrentTab(val);
  };

  useEffect(() => {
    getCompanySettings(company.id)
        .then(res => setCompanySettings(res.data))
        .catch(e => console.log(e));
  }, []);

  return (
      <>
        <Box sx={{mt: "24px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="disabled tabs example">
            <Tab sx={{textTransform: "none"}} value={0} label={"Article Images"}></Tab>
            <Tab sx={{textTransform: "none"}} value={1} label={"Banner Images"}></Tab>
            <Tab sx={{textTransform: "none"}} value={2} label={"Banner copy"}></Tab>
            <Tab sx={{textTransform: "none"}} value={3} label={"Article Image tagline"}></Tab>
            <Tab sx={{textTransform: "none"}} value={4} label={"Image Bank"}></Tab>
            <Tab sx={{textTransform: "none"}} value={5} label={"Logos"}></Tab>
          </Tabs>
        </Box>
        <Box sx={{mt: "24px"}}>
          {currentTab === 0 && <ImageTemplates companySettings={companySettings} company={company} type={"ARTICLE"}/>}
          {currentTab === 1 && <ImageTemplates companySettings={companySettings} company={company} type={"BANNER"}/>}
          {currentTab === 2 && <ImageTitles company={company} type={"BANNER"}/>}
          {currentTab === 3 && <ImageTitles company={company} type={"ARTICLE"}/>}
          {currentTab === 4 && <ImageBank company={company} type={"SINGLE_POST"}/>}
          {currentTab === 5 && <ImageBank company={company} type={"LOGO"}/>}
        </Box>
      </>
  );
};

export default ImageTemplatesTab;
