import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {getNews} from "../services/NewsService";
import {getArticle} from "../services/ArticleService";
import ArticleSummery from "./ArticleSummery";
import {getBrandingBanner} from "../services/BrandingBannerService";
import {useMessageContext} from "../contexts/MessageContext";
import NewsPreview from "./feed/NewsPreview";
import BannerPreview from "./feed/BannerPreview";

const FeedPreview = ({item, handleClose, open}) => {

  const [data, setData] = useState();

  const {notificationHandler} = useMessageContext();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (item.type.includes("NEWS") || item.type.includes("WEEKLY_DIGEST") || item.type.includes("NEWS_GENERAL") || item.type.includes("NEWS_FINANCE")) {
      getNews(item.entityId)
          .then(res => setData(res.data))
    } else if (item.type.includes("ARTICLE") || item.type.includes("SINGLE_POST")) {
      getArticle(item.entityId)
          .then(res => setData(res.data))
    } else if (item.type.includes("BANNER")) {
      getBrandingBanner(item.entityId)
          .then(res => setData(res.data))
    }

  }, [reload])

  return (
      <>
        <Dialog
            sx={{"& .MuiDialog-paper": {minWidth: "70%"}}}
            open={open}
            onClose={handleClose}>
          <DialogTitle>Preview</DialogTitle>
          {data &&
          <DialogContent>
            {(item.type.includes("NEWS") || item.type.includes("WEEKLY_DIGEST") || item.type.includes("NEWS_GENERAL") || item.type.includes("NEWS_FINANCE")) &&
              <NewsPreview data={data} item={item}/>}
            {(item.type.includes("ARTICLE") || item.type.includes("SINGLE_POST")) && <>
            {data && <ArticleSummery companyId={item.companyId} setReload={setReload} element={data} isArticle={item.type.includes("ARTICLE")}/>}</>}
            {item.type.includes("BANNER") && <BannerPreview data={data} notificationHandler={notificationHandler}/>}
          </DialogContent>}
          <DialogActions>
            <Button variant={"text"} onClick={handleClose}>close</Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default FeedPreview;
