import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {useState} from "react";
import {fullManualNews} from "../../services/NewsService";
import {useMessageContext} from "../../contexts/MessageContext";

const NewsPlayground = ({setLoading, companies, type}) => {

  const {notificationHandler} = useMessageContext();


  const [data, setData] = useState({
    days: 5,
    maxResults: 5,
    topic: "news",
    query: null,
    company: null,
    advance: true,
    includeAnswer: null,
    includeImages: null,
    includeRawContent: null,
    includeDomains: [],
    excludeDomains: []
  });

  const handleSubmit = () => {
    setLoading(true);

    fullManualNews({
      newsType: type,
      days: data.days,
      maxResults: data.maxResults,
      topic: data.topic,
      query: data.query,
      companyId: data.company?.id,
      advance: data.advance,
      includeAnswer: data.includeAnswer,
      includeImages: data.includeImages,
      includeRawContent: data.includeRawContent,
      includeDomains: data.includeDomains,
      excludeDomains: data.excludeDomains

    })
        .then(() => setData({
          days: 5,
          maxResults: 5,
          topic: "news",
          query: null,
          companyId: null,
          advance: true,
          includeAnswer: null,
          includeImages: null,
          includeRawContent: null,
          includeDomains: [],
          excludeDomains: []
        }))
        .then(() => notificationHandler.success('Manual News triggered'))
        .finally(() => setLoading(false));
  }

  return (<>

    <Box sx={{display: "flex", alignItems: "center", flexDirection:"column"}}>
      <TextField
          autoFocus
          required
          multiline
          value={data.query}
          sx={{width: "500px"}}
          margin="dense"
          id="query"
          name="query"
          label="Query"
          onChange={e => setData(prv => {
            return {
              ...prv,
              query: e.target.value
            }
          })}
          type="text"
          variant="standard"/>
      <Box sx={{width: "500px", mt: "12px"}}>
        <TextField
            autoFocus
            required
            value={data.maxResults}
            sx={{width: "240px"}}
            margin="dense"
            onChange={e => setData(prv => {
              return {
                ...prv,
                maxResults: e.target.value
              }
            })}
            id="max"
            name="max"
            label="Mbax Number of Results"
            type="number"
            variant="standard"/>
        <TextField
            autoFocus
            required
            value={data.days}
            onChange={e => setData(prv => {
              return {
                ...prv,
                days: e.target.value
              }
            })}
            sx={{width: "240px", ml: "10px"}}
            margin="dense"
            id="days"
            name="days"
            label="Number Of Days"
            type="number"
            variant="standard"/>
      </Box>
      <Box>
        <FormControl sx={{width: "240px", mt: "8px"}}>
          <InputLabel id="demo-simple-select-label">Company (Optional)</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Company (Optional)"
              variant={"standard"}
              value={data.company}
              onChange={e => setData(prv => {
                return {
                  ...prv,
                  company: e.target.value
                }
              })}
          >

            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {companies.map((company, i) => {
              return <MenuItem value={company}>{company.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <TextField
            autoFocus
            required
            value={data.topic}
            onChange={e => setData(prv => {
              return {
                ...prv,
                topic: e.target.value
              }
            })}
            sx={{width: "240px", ml: "20px"}}
            margin="dense"
            id="topic"
            name="topic"
            label="Topic"
            type="text"
            variant="standard"/>
      </Box>
      <Box sx={{mt: "24px", width: "500px"}}>
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Basic</Typography>
            <Switch defaultChecked checked={data.advance} onChange={() => setData(prv =>{
              return {
                ...prv,
                advance: !prv.advance
              }
            })} />
            <Typography>Advance</Typography>
          </Stack>
        </Box>
        <Box>
          <FormControlLabel  control={<Switch checked={data.includeAnswer} />} label="Include answer" onChange={() => setData(prv =>{
            return {
              ...prv,
              includeAnswer: !prv.includeAnswer
            }
          })} />
        </Box>
        <Box>
          <FormControlLabel control={<Switch checked={data.includeImages} />} label="Include images" onChange={() => setData(prv =>{
            return {
              ...prv,
              includeImages: !prv.includeImages
            }
          })} />
        </Box>
        <Box>
          <FormControlLabel control={<Switch checked={data.includeRawContent}/>} label="Include raw content" onChange={() => setData(prv =>{
            return {
              ...prv,
              includeRawContent: !prv.includeRawContent
            }
          })} />
        </Box>
        <Autocomplete
            multiple
            id="tags-filled"
            options={[].map((option) => option.title)}
            freeSolo
            value={data.includeDomains}
            onChange={(event, newValue) => {
              setData(prv => {
                return {
                  ...prv,
                  includeDomains: newValue
                }
              });
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    sx={{width: "250px"}}
                    {...params}
                    variant="standard"
                    label="Include Domains"
                />
            )}
        />
        <Autocomplete
            multiple
            id="tags-filled"
            options={[].map((option) => option.title)}
            freeSolo
            value={data.excludeDomains}
            onChange={(event, newValue) => {
              setData(prv => {
                return {
                  ...prv,
                  excludeDomains: newValue
                }
              });
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    sx={{width: "250px"}}
                    {...params}
                    variant="standard"
                    label="Exclude Domains"
                />
            )}
        />
      </Box>
      <Button sx={{margin: "24px"}}
              variant={"contained"}
              disabled={data.query == null || data.query === "" ||
                  data.days == null || data.days === "" ||
                  data.maxResults == null || data.maxResults === "" ||
                  data.topic == null || data.topic === ""}
              onClick={handleSubmit}
      >Generate</Button>
    </Box>
</>
  )
};

export default NewsPlayground;
