import {Box, Button, Chip, Paper, TextField} from "@mui/material";
import {getCompanyCustomers, saveCompanyCustomers} from "../../../services/CompanyService";
import {useMessageContext} from "../../../contexts/MessageContext";
import {useEffect, useState} from "react";
import {Autocomplete} from "@mui/lab";

const CompanyCreateCustomerTab = ({company, setField}) => {

  return (
      <>
    <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.currentCustomers}
          onChange={(event, newValue) => {
            setField("currentCustomers", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Current Customers"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.potentialCustomers}
          onChange={(event, newValue) => {
            setField("potentialCustomers", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip key={index} variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Potential Customers"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={company.competitors}
          onChange={(event, newValue) => {
            setField("competitors", newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Competitors"
              />
          )}
      />
    </Box>

      </>
        );
};

export default CompanyCreateCustomerTab;
