import {Box, Button, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {useState} from "react";

const LoginForm = ({handleRestLogin, failedLogin}) => {

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
      <Box component="form" sx={{
        marginTop: {md: "30px", xs: "30px"},
        marginRight: "auto",
        marginLeft: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
          <Box sx={{display: "flex"}}>
            <Typography sx={{
              fontWeight: {md: 700, xs: "600"},
              fontSize: {md: "40px", xs: "28px"},
              lineHeight: {md: "52px", xs: "38px"}
            }} color="primary.main">UpfrontAi</Typography>
          </Box>
            <Box sx={{
              marginTop: "36px",
              width: "90%",
              maxWidth: "414px",
              marginRight: "auto",
              marginLeft: "auto"
            }}>
              <Typography sx={{textAlign: "left"}} color="primary.light">Username</Typography>
              <TextField value={email} onChange={(event) => setEmail(event.target.value)}
                         sx={{width: "100%"}} placeholder="username" id="email"
                         variant="outlined"/>
            </Box>
            <Box sx={{
              marginTop: "28px",
              width: "90%",
              maxWidth: "414px",
              marginRight: "auto",
              marginLeft: "auto"
            }}>
              <Typography sx={{textAlign: "left"}} color="primary.light">Password</Typography>
              <TextField value={password}
                         onChange={(event) => setPassword(event.target.value)}
                         sx={{width: "100%"}} placeholder="***"
                         type={showPassword ? 'text' : 'password'}
                         id="password" variant="outlined"
                         InputProps={{
                           endAdornment: (<InputAdornment position="start">
                                 <IconButton
                                     aria-label="toggle password visibility"
                                     onClick={handleClickShowPassword}
                                     onMouseDown={handleMouseDownPassword}
                                     edge="start"
                                 >
                                   {showPassword ? <VisibilityOff/> : <Visibility/>}
                                 </IconButton>
                               </InputAdornment>),
                         }}/>
            </Box>
            <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto"}}>
              {failedLogin && <Typography color="primary.error" sx={{marginTop: "14px"}}>Bad credentials</Typography>}
            </Box>
            <Box sx={{
              marginTop: {md: "48px", xs: "28px"},
              width: "90%",
              maxWidth: "414px",
              marginRight: "auto",
              marginLeft: "auto",
            }}>
              <Button
                  disabled={password.length < 1 || email.length < 1}
                  type="submit" sx={{width: "100%"}} variant="contained" onClick={handleRestLogin}>
                login
              </Button>
            </Box>
      </Box>
  );
};
export default LoginForm;
