import {Box, Button, Chip, Divider, Paper, TextField} from "@mui/material";
import {saveCompany} from "../../../services/CompanyService";
import {Autocomplete} from "@mui/lab";
import {useMessageContext} from "../../../contexts/MessageContext";

const CompanyCreateInfoPage = ({company, setField}) => {

  return (<>
    <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <TextField
            required
            sx={{width: "48%"}}
            margin="dense"
            id="name"
            name="name"
            label="Company Name"
            type="text"
            onChange={(e) => setField("name", e.target.value)}
            value={company.name}
        />
        <TextField
            sx={{width: "48%"}}
            margin="dense"
            id="industry"
            name="industry"
            label="Company Industry"
            type="text"
            onChange={(e) => setField("industry", e.target.value)}
            value={company.industry}
        />
      </Box>

      <TextField
          required
          multiline
          fullWidth
          minRows={3}
          sx={{width: "100%"}}
          margin="dense"
          id="info"
          name="info"
          label="Company Information"
          type="text"
          onChange={(e) => setField("info", e.target.value)}
          value={company.info}
      />
      <TextField
          multiline
          fullWidth
          minRows={3}
          sx={{width: "100%"}}
          margin="dense"
          id="company Solution"
          name="Company Solution"
          label="Company Solution"
          type="text"
          onChange={(e) => setField("companySolution", e.target.value)}
          value={company.companySolution}
      />
      <TextField
          margin="dense"
          fullWidth
          id="companyVertical"
          name="Company Vertical"
          label="Company Vertical"
          type="text"
          onChange={(e) => setField("companyCategory", e.target.value)}
          value={company.companyCategory}
      />

      <TextField
          margin="dense"
          fullWidth
          id="CompanyValueProposition"
          name="Company Value Proposition"
          label="Company Value Proposition"
          type="text"
          onChange={(e) => setField("valueProposition", e.target.value)}
          value={company.valueProposition}
      />
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
        <Autocomplete
            multiple
            id="tags-filled"
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.personasProduct}
            onChange={(event, newValue) => {
              setField("personasProduct", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} aria-multiline variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Persona Users"
                />
            )}
        />
        <Autocomplete
            multiple
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            id="tags-filled"
            options={[].map((option) => option.title)}
            freeSolo
            value={company.personasSeller}
            onChange={(event, newValue) => {
              setField("personasSeller", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} aria-multiline variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Persona Buyer"
                />
            )}
        />
        <Autocomplete
            multiple
            id="tags-filled"
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.companyDifferentiators}
            onChange={(event, newValue) => {
              setField("companyDifferentiators", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Company Differentiators"
                />
            )}
        />
        <Autocomplete
            multiple
            id="tags-filled"
            sx={{mt: "8px", width: "22%", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.companyStandards}
            onChange={(event, newValue) => {
              setField("companyStandards", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip sx={{ height: 'auto','& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }} variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Customer Standards"
                />
            )}
        />
      </Box>
    </Box>
    </>);
}
export default CompanyCreateInfoPage;
