import httpService from "./HttpService";

export async function manualBrandingBanner(companyId, imageId, titleId) {
  const token = localStorage.getItem("auth");
  return httpService.put(`branding_banner`, {
    companyId,
    imageTemplateId: imageId,
    titleId,
  }, token);
}

export async function getBrandingBanner(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`branding_banner/${id}`, null, token);
}

export async function fullManualBrandingBanner(body) {
  const token = localStorage.getItem("auth");
  return httpService.put(`branding_banner/full`, body, token);
}

export async function publishBanner(bannerId, companyId) {
  const token = localStorage.getItem("auth");
  return httpService.put(`branding_banner/publish`, {
    companyId,
    brandingBannerId: bannerId
  }, token);
}
