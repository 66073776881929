import {
  getCompanySettings,
  saveCompany, saveCompanySettings
} from "../../../services/CompanyService";
import {
  Box, Button, Chip, Divider, FormControl, FormLabel, MenuItem, Paper, Select, TextField, Typography
} from "@mui/material";
import {useMessageContext} from "../../../contexts/MessageContext";
import {useEffect, useState} from "react";
import {Autocomplete} from "@mui/lab";

const CompanyCreateSettingsTab = ({setImageFields, company, setField}) => {
  const brandArchetypes = [
    {
      value: "THE_CAREGIVER",
      name: "The Caregiver",
      description: "Traits: Compassionate, nurturing, selfless, supportive. Example: Allstate – emphasizes care and support for families."
    },
    {
      value: "THE_RULER",
      name: "The Ruler",
      description: "Traits: Authoritative, organized, responsible, decisive. Example: Rolex, microsoft – positioned as a symbol of luxury, precision, and status."
    },
    {
      value: "THE_CREATOR",
      name: "The Creator",
      description: "Traits: Innovative, imaginative, artistic, visionary. Example: apple – known for fostering creativity and imagination in technology."
    },
    {
      value: "THE_INNOCENT",
      name: "The Innocent",
      description: "Traits: Pure, optimistic, simple, honest. Example: Coca-Cola – often associated with happiness, joy, and simplicity."
    },
    {
      value: "THE_SAGE",
      name: "The Sage",
      description: "Traits: Wise, knowledgeable, analytical, insightful. Example: Google – positioned as a source of knowledge and information."
    },
    {
      value: "THE_EXPLORER",
      name: "The Explorer",
      description: "Traits: Adventurous, independent, ambitious, freedom-seeking. Example: The Jeep – appeals to those who seek adventure and exploration."
    },
    {
      value: "THE_REBEL",
      name: "The Rebel",
      description: "Traits: Outlaw, revolutionary, nonconformist, daring. Example: Harley-Davidson – associated with freedom and rebellion."
    },
    {
      value: "THE_MAGICIAN",
      name: "The Magician",
      description: "Traits: Visionary, imaginative, transformative, charismatic. Example: Disney – known for its innovative and transformative products."
    },
    {
      value: "THE_HERO",
      name: "The Hero",
      description: "Traits: Courageous, strong, determined, triumphant. Example: Nike – known for its empowering and motivational messaging."
    },
    {
      value: "THE_LOVER",
      name: "The Lover",
      description: "Traits: Passionate, sensual, romantic, committed. Example: Victoria's Secret – known for its emphasis on sensuality and romance."
    },
    {
      value: "THE_JESTER",
      name: "The Jester",
      description: "Traits: Playful, humorous, irreverent, carefree. Example: M&M's – uses humor and playfulness in its advertising."
    },
    {
      value: "THE_REGULAR_GUY",
      name: "The Regular Guy/Girl (Everyman)",
      description: "Traits: Down-to-earth, relatable, genuine, friendly. Example: IKEA – positions itself as an accessible and affordable brand for everyone."
    }
  ];


  return (
      <>
     <FormControl fullWidth sx={{mt: "16px"}}>
          <FormLabel>Brand Archetype</FormLabel>
          <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Brand Archetype"
              variant={"standard"}
              value={company.brandArchetype}
              onChange={(e) => setField("brandArchetype", e.target.value)}
          >

            {brandArchetypes.map((archetype, i) => {
              return <MenuItem key={i} value={archetype.value}>
                <Box>
                <Typography sx={{fontSize: "14px",fontWeight: 600}}>{archetype.name}</Typography>
                <Typography sx={{fontSize: "12px"}}>{archetype.description}</Typography>
                </Box></MenuItem>
            })}
          </Select>
        </FormControl>
        <TextField
            sx={{mt: "16px"}}
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="agentRole"
            name="agentRole"
            label="Agent Role"
            type="text"
            onChange={e => setField("agentRole", e.target.value)}
            value={company.agentRole}
        />
        <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.fonts}
            onChange={(event, newValue) => {
              setField("fonts", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip key={index} variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Corporate Fonts"
                />
            )}
        />
        <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.colors}
            onChange={(event, newValue) => {
              setField("colors", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Corporate Colors"
                />
            )}
        />
        <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={company.industryPublication}
            onChange={(event, newValue) => {
              setField("industryPublication", newValue)
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip key={index} variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Industry Publication"
                />
            )}
        />
        <TextField
            sx={{mt: "16px"}}
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="newsQuery"
            name="newsQuery"
            label="News Query"
            type="text"
            onChange={e => setField("newsQuery", e.target.value)}
            value={company.newsQuery}
        />
        <Divider sx={{mt: "16px"}}>Brand Banners</Divider>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <TextField
              sx={{width: "47%"}}
              margin="dense"
              id="fontSize"
              name="fontSize"
              label="Font Size"
              type="number"
              onChange={(e) => setImageFields("fontSize", e.target.value, "bannerDefaults")}
              value={company.bannerDefaults?.fontSize}
          />
          <TextField
              sx={{width: "47%"}}
              margin="dense"
              id="color"
              name="color"
              label="Font Color"
              type="text"
              onChange={(e) => setImageFields("color", e.target.value, "bannerDefaults")}
              value={company.bannerDefaults?.color}
          />
        </Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="x"
              name="x"
              label="x"
              type="number"
              onChange={(e) => setImageFields("x", e.target.value, "bannerDefaults")}
              value={company.bannerDefaults?.x}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="y"
              name="y"
              label="y"
              type="number"
              onChange={(e) => setImageFields("y", e.target.value, "bannerDefaults")}
              value={company.bannerDefaults?.y}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxWidth"
              name="maxWidth"
              label="Max Width"
              type="number"
              onChange={(e) => setImageFields("maxWidth", e.target.value, "bannerDefaults")}
              value={company.bannerDefaults?.maxWidth}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxHeight"
              name="maxHeight"
              label="Max Height"
              type="text"
              onChange={(e) => setImageFields("maxHeight", e.target.value, "bannerDefaults")}
              value={company.bannerDefaults?.maxHeight}
          />
        </Box>
        <Divider sx={{mt: "16px"}}>Article Image</Divider>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <TextField
              sx={{width: "47%"}}
              margin="dense"
              id="fontSize"
              name="fontSize"
              label="Font Size"
              type="number"
              onChange={(e) => setImageFields("fontSize", e.target.value, "articleDefaults")}
              value={company.articleDefaults?.fontSize}
          />
          <TextField
              sx={{width: "47%"}}
              margin="dense"
              id="color"
              name="color"
              label="Font Color"
              type="text"
              onChange={(e) => setImageFields("color", e.target.value, "articleDefaults")}
              value={company.articleDefaults?.color}
          />
        </Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="x"
              name="x"
              label="x"
              type="number"
              onChange={(e) => setImageFields("x", e.target.value, "articleDefaults")}
              value={company.articleDefaults?.x}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="y"
              name="y"
              label="y"
              type="number"
              onChange={(e) => setImageFields("y", e.target.value, "articleDefaults")}
              value={company.articleDefaults?.y}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxWidth"
              name="maxWidth"
              label="Max Width"
              type="number"
              onChange={(e) => setImageFields("maxWidth", e.target.value, "articleDefaults")}
              value={company.articleDefaults?.maxWidth}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxHeight"
              name="maxHeight"
              label="Max Height"
              type="text"
              onChange={(e) => setImageFields("maxHeight", e.target.value, "articleDefaults")}
              value={company.articleDefaults?.maxHeight}
          />
        </Box>
        </>
      );
};

export default CompanyCreateSettingsTab;
