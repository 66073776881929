export const CompanyFeatureType = {
  NEWS: "News",
  WEEKLY_DIGEST: "Weekly Digest",
  NEWS_GENERAL: "News General",
  NEWS_FINANCE: "News Finance",
  SINGLE_POST: "Single Post",
  ARTICLE: "Article",
  SEO: "SEO",
  BRANDING_BANNER: "Branding Banner"
}
