import {Box, Button, Chip, Paper, TextField} from "@mui/material";
import {getCompanyCustomers, saveCompanyCustomers} from "../../../services/CompanyService";
import {useMessageContext} from "../../../contexts/MessageContext";
import {useEffect, useState} from "react";
import {Autocomplete} from "@mui/lab";

const CompanyCustomerTab = ({company, setLoader}) => {

  const {notificationHandler} = useMessageContext();

  const [companyCustomers, setCompanyCustomers] = useState();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoader(true);
    getCompanyCustomers(company.id)
        .then(res => {
          setCompanyCustomers(res.data);
        }).then(() => setLoader(false))
  }, [reload]);

  const updateCurrentCustomers = (currentCustomers) => {
    setCompanyCustomers(prv => {
      return {...prv, currentCustomers}
    })
  }

  const updatePotentialCustomers = (potentialCustomers) => {
    setCompanyCustomers(prv => {
      return {...prv, potentialCustomers}
    })
  }

  const updateCompetitors = (competitors) => {
    setCompanyCustomers(prv => {
      return {...prv, competitors}
    })
  }

  return (<Paper component={"form"}
                 onSubmit={(event => {
                   event.preventDefault();
                   setLoader(true);
                   // const formData = new FormData(event.currentTarget);
                   // const formJson = Object.fromEntries(formData.entries());
                   companyCustomers.companyId = company.id;
                   saveCompanyCustomers(companyCustomers)
                       .then(() => setReload(prv => !prv))
                       .then(() => notificationHandler.success('Company Saved'))
                       .finally(() => setLoader(false))
                 })}
                 sx={{margin: "48px", padding: "24px"}} elevation={2} >
    {companyCustomers && <Box sx={{display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center"}}>
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyCustomers.currentCustomers}
          onChange={(event, newValue) => {
            updateCurrentCustomers(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Current Customers"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyCustomers.potentialCustomers}
          onChange={(event, newValue) => {
            updatePotentialCustomers(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Potential Customers"
              />
          )}
      />
      <Autocomplete
          multiple
          fullWidth
          id="tags-filled"
          sx={{mt: "16px", "& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
          options={[].map((option) => option.title)}
          freeSolo
          value={companyCustomers.competitors}
          onChange={(event, newValue) => {
            updateCompetitors(newValue);
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip variant="filled" label={option} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
              <TextField
                  fullWidth
                  {...params}
                  label="Competitors"
              />
          )}
      />
      <Box sx={{mt: "48px", display: "flex", alignItems: "center", justifyContent: "end"}}>
        <Button type="submit" variant={"contained"}>save</Button>
      </Box>
    </Box>}
  </Paper>);
};

export default CompanyCustomerTab;
