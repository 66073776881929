import {useLinkedIn} from "react-linkedin-login-oauth2";
// You can use provided image shipped by this package or using your own
import {
  Box, Button, Chip, Table, TableBody, TableCell, TableHead, TableRow, TextField
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {
  deleteIntegration,
  getIntegration, saveWordpressIntegration
} from "../../services/IntegrationService";
import {Autocomplete} from "@mui/lab";

const CompanyIntegration = ({company}) => {
  const [integrations, setIntegrations] = useState([])
  const [reload, setReload] = useState(false);
  const [orgId, setOrgId] = useState(null)
  const [integratingList, setIntegratingListList] = useState(["LINKEDIN", "WORDPRESS"]);

  const base64 = require('base-64');

  const { linkedInLogin } = useLinkedIn({
    clientId: '77zh6n99phm6z5',
    scope: 'r_basicprofile w_member_social w_organization_social',
    redirectUri: `${window.location.origin}/app/linkdin?data=${base64.encode(JSON.stringify({companyId: company.id, orgId: orgId}))}`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      setReload(prv => !prv)
    },
    onError: (error) => {
      setReload(prv => !prv)
    },
  });

  useEffect(() => {
    getIntegration(company.id)
        .then(res => setIntegrations(res.data))
  }, [reload]);

  const logout = (type) =>{
    deleteIntegration(company.id, type)
        .then(() => setReload(prv => !prv))
  }

  const getNewWordPressRow = () => {
    return <TableRow sx={{height: "250px"}}>
      <TableCell>
        Wordpress
      </TableCell>
      <TableCell>
        NOT CONNECTED
      </TableCell>
      <TableCell sx={{width: "50%"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="userName"
              name="userName"
              label="userName"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="siteUrl"
              name="siteUrl"
              label="Site URL"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="categoryId"
              name="categoryId"
              label="Category Id"
              type="number"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="firstImage"
              name="firstImage"
              label="Image #1"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="paragraphNumberOne"
              name="paragraphNumberOne"
              label="Paragraph #1"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="imageUrlOne"
              name="imageUrlOne"
              label="Image Link Url #1"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="secondImage"
              name="secondImage"
              label="Image #2"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="paragraphNumberTwo"
              name="paragraphNumberTwo"
              label="Paragraph #2"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="imageUrlTwo"
              name="imageUrlTwo"
              label="Image Link Url #2"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="video"
              name="video"
              label="video (Optional)"
              type="text"
              variant="standard"
          />
          <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="video_paragraph"
              name="video_paragraph"
              label="video Paragraph (Optional)"
              type="text"
              variant="standard"
          />
        </Box>
      </TableCell>
      <TableCell align={"right"}>
        <Button type={"submit"}>
          Login
        </Button>
      </TableCell>
    </TableRow>
  }

  const getEditWordPressRow = (integrationValue) => {
    return <TableRow sx={{height: "250px"}}>
      <TableCell>
        Wordpress
      </TableCell>
      <TableCell>
        {integrationValue.status || "NOT CONNECTED"}
      </TableCell>
      <TableCell sx={{width: "50%"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Site Url:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.SITE_URL}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Category Id:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.CATEGORY_ID}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Image #1:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.IMAGE_URL_1}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Image Paragraph #1:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.IMAGE_PARAGRAPH_1}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Image Link URL #1:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.IMAGE_LINK_URL_1}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Image #2:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.IMAGE_URL_2}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Image Paragraph #2:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.IMAGE_PARAGRAPH_2}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Image Link URL #2:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.IMAGE_LINK_URL_2}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Video URL:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.VIDEO_URL}</Box>
          </Box>
          <Box sx={{display: "flex", mt: "12px"}}>
            <Box sx={{fontWeight: 600}}>Video Paragraph:</Box>
            <Box sx={{ml: "12px"}}>{integrationValue.properties.VIDEO_PARAGRAPH}</Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell align={"right"}>
        <Button onClick={() => logout("WORDPRESS")}>
          Logout
        </Button>
      </TableCell>
    </TableRow>
  }

  const getIntegrationRow = (integration) => {

    const integrationValue = integrations.find(item => item.type === integration);


    if (integration === 'LINKEDIN') {
      return (<TableRow>
        <TableCell>
          Linkedin
        </TableCell>
        <TableCell>
          {integrationValue?.status || "NOT CONNECTED"}
        </TableCell>
        <TableCell>
          {integrationValue?.status !== 'CONNECTED' ? <>
            <TextField
                autoFocus
                value={orgId}
                onChange={(e) => setOrgId(e.target.value)}
                sx={{width: "150px"}}
                margin="dense"
                id="orgId"
                name="orgId"
                label="orgId"
                type="text"
                variant="standard"
            />
          </> : "Org Id: " + integrationValue?.properties.ORGID}
        </TableCell>
        <TableCell align={"right"}>
          {integrationValue?.status === 'CONNECTED' ?
              <Button onClick={() => logout('LINKEDIN')}>
                Logout
              </Button> :
              <Button onClick={linkedInLogin}>
                Login
              </Button>}
        </TableCell>
      </TableRow>)
    } else if (integration === 'WORDPRESS') {
      return <>
        {integrationValue ? getEditWordPressRow(integrationValue) : getNewWordPressRow()}

      </>
    }
  }

  const handleWordpressLogin = (event) => {
      event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
      console.log(formJson)

    saveWordpressIntegration({
      companyId: company.id,
      userName: formJson.userName,
      password: formJson.password,
      siteUrl: formJson.siteUrl,
      categoryId: formJson.categoryId,
      imageUrl1: formJson.firstImage,
      imageLinkUrl1: formJson.imageUrlOne,
      imageParagraphNumber1: formJson.paragraphNumberOne,
      imageUrl2: formJson.secondImage,
      imageLinkUrl2: formJson.imageUrlTwo,
      imageParagraphNumber2: formJson.paragraphNumberTwo,
      videoUrl: formJson.video,
      videoParagraph: formJson.video_paragraph
    }).then(r => setReload(prv => !prv))
  }

  return (
      <Box sx={{mt: "24px"}} component={"form"} onSubmit={handleWordpressLogin}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Required fields
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          </TableBody>
          {integratingList.map(integration => {
            return getIntegrationRow(integration)
          })}

        </Table>
      </Box>

  );
}

export default CompanyIntegration;
