import {Navigate} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login';
import Page404 from "./pages/Page404";
import {useAuth} from "./contexts/AuthContext";
import Feed from "./pages/Feed";
import Companies from "./pages/Companies";
import Playground from "./pages/Playground";
import CompanyPage from "./pages/CompanyPage";
import Linkdin from "./pages/linkdin";

function NavigateGuard() {
  const {loggedIn} = useAuth();
  return loggedIn ? <Navigate to="/app/feed"/> : <Navigate to="/login" />;
}

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'playground', element: <Playground /> },
      { path: 'Linkdin', element: <Linkdin /> },
      { path: 'companies/:id', element: <CompanyPage /> },
      { path: 'companies', element: <Companies /> },
      { path: 'feed', element: <Feed /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <NavigateGuard/> },
      { path: '*', element: <Page404 to="/404" /> }
    ]
  }
];

export default routes;
