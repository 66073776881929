import {useEffect, useState} from "react";
import {
  deleteImageTitle, generateImageTitles,
  getImageTitles,
  saveImageTitle
} from "../services/ImageTemplateService";
import {
  Box, SpeedDial, SpeedDialAction, SpeedDialIcon, Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AddEditImageTitle from "./AddEditImageTile";
const ImageTitles = ({company, type}) => {

  const [titles, setTitles] = useState([]);

  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState({
    open: false,
    title: {},
    isEdit: false
  });

  useEffect(() => {
    getImageTitles(company.id, type)
        .then(res => setTitles(res.data))
  }, [reload]);

  const addNewTitle = (titles, statusSelected, fullTitle, isEdit) => {
    setLoading(true);

    if (isEdit) {
      saveImageTitle({
        title: titles,
        companyId: company.id,
        type: type,
        status: statusSelected,
        id: fullTitle?.id || null
      }).then(() => setOpen({open: false, title: {}}))
          .then(() => setLoading(false))
          .then(() => setReload(prv => !prv))
    } else {
      saveImageTitle({
        titles: titles,
        companyId: company.id,
        status: "AVAILABLE",
        type: type,
      }).then(() => setOpen({open: false, title: {}}))
          .then(() => setLoading(false))
          .then(() => setReload(prv => !prv))
    }
  }

  const handleDelete = (id) => {
    setLoading(true);
    deleteImageTitle(id)
        .then(() => setReload(prv => !prv))
        .then(() => setLoading(false))
  }

  const generateTitles = () => {
    setLoading(true);
    generateImageTitles(company.id, type)
        .then(() => setReload(prv => !prv))
        .then(() => setLoading(false))
  }

  const handleEdit = (imageTitle) => {
    setOpen({open: true, title: imageTitle, isEdit: true})
  }

  const actions = [
    { icon: <AddIcon />, name: 'Add', onClick: () => setOpen({open: true, title: {}, isEdit: false})},
    { icon: <AutoModeIcon />, name: 'Generate titles', onClick: generateTitles },
  ];

  return<Box>
    <AddEditImageTitle isEdit={open.isEdit} open={open.open} type={type} title={open.title} handleClose={() => setOpen(prv => {
    return {
      ...prv, open: false, isEdit: false
    }})} onSubmit={addNewTitle}/>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Title</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Posting Date</TableCell>
        <TableCell align={"right"}>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {titles.map((title, index) => <TableRow key={index}>
        <TableCell>{title.title}</TableCell>
        <TableCell>{title.status}</TableCell>
        <TableCell>{title.postingDate}</TableCell>
        <TableCell>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
            <>
            <Box sx={{cursor: "pointer"}} onClick={() => handleDelete(title.id)}>
              <DeleteIcon/>
            </Box>
            <Box sx={{cursor: "pointer"}} onClick={() => handleEdit(title)}>
              <EditIcon/>
            </Box></>
          </Box>
        </TableCell>
      </TableRow>)}
    </TableBody>
    </Table>
    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                onClick={action.onClick}
                tooltipTitle={action.name}
            />
        ))}
      </SpeedDial>
    </Box>
  </Box>

};

export default ImageTitles;
