import {Box, Tab, Tabs} from "@mui/material";
import {useState} from "react";
import CompanyInfoPage from "./CompanyInfoPage";
import CompanyCustomerTab from "./CompanyCustomerTab";
import CompanyKeywordsTag from "./CompanyKeywordsTag";
import CompanyAboutTab from "./CompanyAboutTab";
import CompanyDocsTab from "./CompanyDocsTab";
import CompanyProductTab from "./CompanyProductTab";
import CompanySettingsTab from "./CompanySettingsTab";

const CompanyTabs = ({setLoader, company, setRefresh, setCompany, setDeleteCompanyDialog}) => {

  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, val) => {
    setCurrentTab(val);
  };

  return (
      <>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
    <Tabs value={currentTab} onChange={handleChange} >
      <Tab sx={{textTransform: "none"}} label="Profile" />
      <Tab sx={{textTransform: "none"}} label="Customers and Competitors" />
      <Tab sx={{textTransform: "none"}} label="Product" />
      <Tab sx={{textTransform: "none"}} label="Keywords" />
      <Tab sx={{textTransform: "none"}} label="Company Documents" />
      <Tab sx={{textTransform: "none"}} label="About" />
      <Tab sx={{textTransform: "none"}} label="Settings" />
    </Tabs>
  </Box>
        {currentTab === 0 && <CompanyInfoPage setDeleteCompanyDialog={setDeleteCompanyDialog} setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
        {currentTab === 1 && <CompanyCustomerTab setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
        {currentTab === 2 && <CompanyProductTab setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
        {currentTab === 3 && <CompanyKeywordsTag setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
        {currentTab === 4 && <CompanyDocsTab setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
        {currentTab === 5 && <CompanyAboutTab setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
        {currentTab === 6 && <CompanySettingsTab setLoader={setLoader} setCompany={setCompany} company={company} setRefresh={setRefresh}/>}
      </>
  );
};

export default CompanyTabs;
