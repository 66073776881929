import httpService from "./HttpService";
import {value} from "lodash/seq";

export async function getFeed(companyId, type, isManual, isSeo) {
  const token = localStorage.getItem("auth");
  let url = 'feed';

  let params = [{
    name: "companyId",
    value: companyId
  }, {
    name: "type",
    value: type
  }, {
    name: "isManual",
    value: isManual
  }, {
    name: "isSeo", value: isSeo
  }];


  let first = true;
  params.map(param => {
    if (param.value != null && param.value !== "") {
      if (first) {
        url += `?${param.name}=${param.value}`
        first = false;
      } else {
        url += `&${param.name}=${param.value}`
      }
    }
  });

  return httpService.get(url, null, token);
}

export async function deleteFeed(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`feed/${id}`, null, token);
}
