import React, {useContext, useState} from "react";
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

const MessageContext = React.createContext({ undefined });

export function useMessageContext() {
  return useContext(MessageContext);
}

export function MessageProvider({children}) {
  const [snackOpen, setSnackOpen] = useState({
    open: false,
    severity: 'info',
    msg: ''
  });


  const notificationHandler = {
    success: message => showNotification('success', message),
    error: message => showNotification('error', message),
    info: message => showNotification('info', message),
    warning: message => showNotification('warning', message),
  };

  const showNotification = (severity, msg) => {
    setSnackOpen({
      open: true,
      severity: severity,
      msg: msg
    })
  }

  const value = {
    notificationHandler
  };

  return (
      <MessageContext.Provider value={value}>
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={snackOpen.open}
                  autoHideDuration={3000}
                  onClose={() => setSnackOpen({
                    open: false,
                    severity: snackOpen.severity,
                    msg: ''
                  })}>

          <Alert onClose={() => setSnackOpen({
            open: false,
            severity: snackOpen.severity,
            msg: ''
          })} severity={snackOpen.severity} sx={{ width: '100%' }}>
            {snackOpen.msg}
          </Alert>
        </Snackbar>
        {children}
      </MessageContext.Provider>
  );
}
