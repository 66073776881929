import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";

const AreYouSure = ({title, open, handleClose, onSubmit}) => {
  return (<Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          onSubmit();
        },
      }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color={"error"} onClick={handleClose}>Cancel</Button>
      <Button variant={"contained"} type="submit">Yes</Button>
    </DialogActions>
  </Dialog>);
};

export default AreYouSure;
