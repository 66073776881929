import httpService from "./HttpService";

export async function getScheduler(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`scheduler/${companyId}`, null, token);
}

export async function saveScheduler(schedulerSetting) {
  const token = localStorage.getItem("auth");
  return httpService.post(`scheduler`, schedulerSetting, token);
}

export async function deleteScheduler(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`scheduler/${id}`, null, token);
}
