import {Box, MenuItem, TableCell, TableRow} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FeedPreview from "./FeedPreview";
import {useState} from "react";
import moment from "moment/moment";
import {deleteFeed} from "../services/FeedService";
import {useMessageContext} from "../contexts/MessageContext";

const FeedElement = ({item, setReload}) => {

  const [preview, setPreview] = useState()
  const obj = {
    NEWS: "News",
    WEEKLY_DIGEST: "Weekly Digest",
    NEWS_GENERAL: "General News",
    NEWS_FINANCE: "Finance News",
    SINGLE_POST: "Single Post",
    ARTICLE: "Article",
    BANNER: "Branding Banner",
  }

  const {notificationHandler} = useMessageContext();

  const deleteMe = () => {
    deleteFeed(item.id)
        .then(() => notificationHandler.success('Feed deleted'))
        .then(() => setReload())
  };

  return (<>
        {preview && <FeedPreview item={item} open={preview} handleClose={() => setPreview(false)} />}
      <TableRow
          sx={{"&.MuiTableRow-root" : {
              border: "1px solid #E7E9EE",
              height: "100px",
              backgroundColor: "#F9FAFB",
              "&:hover" : {
                backgroundColor: "#dec7f6"
              },
              boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
            }}}>
        <TableCell
            onClick={() => setPreview(true)}
            align={"center"} sx={{cursor: "pointer"}}>
          {moment(item.createdDate).format("DD/MM/YY HH:mm:ss")}
        </TableCell>
        <TableCell
            onClick={() => setPreview(true)}
            align={"center"} sx={{cursor: "pointer"}}>
          {item.companyName}
        </TableCell>
        <TableCell
            onClick={() => setPreview(true)}
            align={"center"} sx={{cursor: "pointer"}}>
          {obj[item.type]}
        </TableCell>
        <TableCell  align={"center"}>
          {item.status}
        </TableCell>
        <TableCell  align={"center"}>
          {item.manual ? "Yes" : "No"}
        </TableCell>
        <TableCell  align={"center"}>
          {item.postedDate ? moment(item.postedDate).format("DD/MM/YY HH:mm:ss") : "N/A"}
        </TableCell>
        <TableCell align={"center"}>
          <Box sx={{cursor: "pointer"}} onClick={() => deleteMe()}>
          <DeleteIcon/>
          </Box>
        </TableCell>
      </TableRow>
      </>
  )
};

export default FeedElement;
