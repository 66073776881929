import {Paper, Typography} from "@mui/material";

const CompanyProductTab = () => {

  return (
      <Paper sx={{padding: "24px", display: "flex", justifyContent: "center"}}>
        <Typography sx={{fontSize: "24px", fontWeight: 600}}>TBD</Typography>
      </Paper>
  );
};

export default CompanyProductTab;
