export const TitleFormats = {
  FEELING_LUCKY: "I'm feeling lucky",
  A_VS_B: "Comparison A Vs B",
  PARAGRAPHED_STORY: "Paragraphed Story",
  STEP_BY_STEP_GUIDE: "Step by Step Guide",
  QUESTION_AND_ANSWERS: "Question and Answers",
  DO_AND_DONT: "Do's and Dont's Pairs",
  HOW_TO: "How to…",
  WHERE_WHAT_WHY: "Where, What, Why",
  WHAT_IF: "What if…",
  MISTAKES_TO_AVOID: "Mistakes To Avoid",
  EVERYTHING_YOU_NEED_TO_KNOW: "Everything You Need To Know About",
  HERE_IS_WHY: "Here’s Why",
  KEYWORD: "…:Keyword:…",
  STOP_DOING_THIS: "Stop Doing This",
  INCREASE_YOUR: "Increase Your… With……",
  THE_SIMPLE: "The Simple……"
}
