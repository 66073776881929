import React, {useContext, useEffect, useState} from 'react';
import {getCompanies} from "../services/CompanyService";

const AuthContext = React.createContext({ undefined });

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(null);

  const login = (token) => {
    // validate user
    setLoggedIn(true);
    localStorage.setItem('auth', token);
  };

  const logout = () => {
    localStorage.removeItem('auth');
    setLoggedIn(null);
  };

  useEffect( () => {
    setLoading(true);
    const token = localStorage.getItem('auth');

    if (token != null) {
      login(token)

      getCompanies().catch(e => {
        logout();
        console.log(e);
      })
    }

    setLoading(false);
  }, []);

  const value = {
    login,
    logout,
    loggedIn
  };

  return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
  );
}
