import httpService from "./HttpService";

export async function getCompanies() {
  const token = localStorage.getItem("auth");
  return httpService.get('company', null, token);
}

export async function getCompany(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`company/${id}`, null, token);
}

export async function getCompanyCustomers(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`company/customers/${companyId}`, null, token);
}

export async function saveCompanyCustomers(customers) {
  const token = localStorage.getItem("auth");
  return httpService.post(`company/customers`, customers, token);
}

export async function getCompanyKeywords(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`company/keywords/${companyId}`, null, token);
}

export async function saveCompanyKeywords(keywords) {
  const token = localStorage.getItem("auth");
  return httpService.post(`company/keywords`, keywords, token);
}

export async function getCompanyDocs(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`company/docs/${companyId}`, null, token);
}

export async function saveCompanyDocs(docs) {
  const token = localStorage.getItem("auth");
  return httpService.post(`company/docs`, docs, token);
}

export async function getCompanySettings(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`company/settings/${companyId}`, null, token);
}

export async function saveCompanySettings(settings) {
  const token = localStorage.getItem("auth");
  return httpService.post(`company/settings`, settings, token);
}

export async function deleteCompanyDocs(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`company/docs/${id}`, null, token);
}

export async function saveCompany(company) {
  const token = localStorage.getItem("auth");
  return httpService.post(`company`, company, token);
}

export async function createCompany(company) {
  const token = localStorage.getItem("auth");
  return httpService.put(`company`, company, token);
}

export async function deleteCompany(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`company/${id}`, null, token);
}

export async function getCompanyTitles(id, format) {
  const token = localStorage.getItem("auth");

  let url = `company/${id}/titles`;

  if (format) {
    url += "?format="+ format;
  }

  return httpService.get(url, null, token);
}

export async function updateCompanyTitles(companyTitle) {
  const token = localStorage.getItem("auth");
  return httpService.post(`company/titles`, companyTitle, token);
}

export async function deleteCompanyTitles(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`company/titles/${id}`, null, token);
}

export async function regenerateTitles(id) {
  const token = localStorage.getItem("auth");
  return httpService.put(`company/titles/${id}/regenerate`, null, token);
}
