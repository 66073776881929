import httpService from "./HttpService";

export async function manualArticle(companyId, titleId,
    articleImageId = null,
    articleImageTitleId = null,
    imageBankId = null,
    type, selectedFormat) {
  const token = localStorage.getItem("auth");
  return httpService.put(`article`, {
    companyId,
    titleId,
    articleImageId,
    articleImageTitleId,
    imageBankId,
    format: selectedFormat,
    type
  }, token);
}

export async function getArticle(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`article/${id}`, null, token);
}

export async function saveArticle(feed) {
  const token = localStorage.getItem("auth");
  return httpService.post(`article/data`, feed, token);
}

export async function fullManualArticle(body) {
  const token = localStorage.getItem("auth");
  return httpService.put(`article/full`, body, token);
}

export async function publishArticle(articleId) {
  const token = localStorage.getItem("auth");
  return httpService.put(`article/publish`, {
    articleId,
  }, token);
}

export async function deleteArticleImage(articleId) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`article/image/${articleId}`, null, token);
}

export async function updateArticleImage(articleId, imageId, tagLineId) {
  const token = localStorage.getItem("auth");
  return httpService.put(`article/image`, {
    articleId,
    imageId,
    tagLineId
  }, token);
}
