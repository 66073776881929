import {Box, Grid, MenuItem, Paper, Tooltip, Typography} from "@mui/material";

const CalenderDay = ({date, calenderData}) => {
  const colors = {
    NEWS: "#6cb1f9",
    WEEKLY_DIGEST: "#54e0e7",
    NEWS_GENERAL : "#1E90FF",
    NEWS_FINANCE: "#7dbdc9",
    SINGLE_POST: "#FFD3F4",
    ARTICLE: "#ae15fc",
    SEO: "#d48b70",
    BRANDING_BANNER: "#b8dbf0"
  }

  const FeatureType = {
    NEWS: "News",
    WEEKLY_DIGEST: "Weekly Digest",
    NEWS_GENERAL: "News General",
    NEWS_FINANCE: "News Finance",
    SINGLE_POST: "Single Post",
    ARTICLE: "Article",
    BRANDING_BANNER: "Branding Banner"
  }
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
  const oneDay = 1000 * 60 * 60 * 24;
  let currentDayOfYear =  Math.floor(diff / oneDay);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const  getColorByType = (type) => {
    return colors[type] ? colors[type] : "rgb(188, 195, 229)";
  }

  return <Grid md={2}>
    <Paper elevation={0} sx={{borderRadius: 0, height: "110px", padding: "10px", border: 1, borderColor: "rgb(218,220,224)"}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Box>
            <Typography sx={{fontSize: "11px", color: "#70757a", fontWeight: 500}}>
              {date.toLocaleString("en-US", { weekday: "short"})}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{color: "rgb(60,64,67)", fontSize: "12px", fontWeight: 500}}>
              {date.toLocaleString("en-US", {month: "long", day: "numeric"})}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {calenderData.filter(data => data.dayOfYear === currentDayOfYear).map(data => {
          return <Box sx={{m: "2px", padding: "2px", backgroundColor: getColorByType(data.featureType), borderRadius: "2px", width: "100%"}}>
            {data.title && <Tooltip title={data.title}>
                <Typography sx={{fontSize: "12px",fontWeight: 500}}>{FeatureType[data.featureType]} - {data.title.length <= 20 - FeatureType[data.featureType].length ? data.title: (data.title.substr(0, 20 - FeatureType[data.featureType].length) + "...")}</Typography>
            </Tooltip>
              }
            {!data.title && <Typography sx={{fontSize: "12px",fontWeight: 500}}>{FeatureType[data.featureType]}</Typography>}
          </Box>
        })}
      </Box>
    </Paper>
  </Grid>
};

export default CalenderDay;
