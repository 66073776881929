import httpService from "./HttpService";

export async function saveLinkedinIntegration(companyId, code, orgId,redirectUri ) {
  const token = localStorage.getItem("auth");
  return httpService.put(`integration/linkedin`, {
    companyId,
    code,
    redirectUri,
    orgId,
  }, token);
}

export async function saveWordpressIntegration(data) {
  const token = localStorage.getItem("auth");
  return httpService.put(`integration/wordpress`, data, token);
}

export async function getIntegration(companyId) {
  const token = localStorage.getItem("auth");
  return httpService.get(`integration/${companyId}`, null, token);
}

export async function deleteIntegration(companyId, type) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`integration/${companyId}/${type}`, null, token);
}
