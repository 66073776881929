import {TableCell, TableRow} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";

const CompanyElement = ({item}) => {

  let navigate = useNavigate()

  return (
      <TableRow
          onClick={() => navigate(`/app/companies/${item.id}`)}
          sx={{"&.MuiTableRow-root" : {
              border: "1px solid #E7E9EE",
              height: "100px",
              backgroundColor: "#F9FAFB",
              cursor: "pointer",
              "&:hover" : {
                backgroundColor: "#dec7f6"
              },
              boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
            }}}>
        <TableCell align={"center"}>
          {item.name}
        </TableCell>
        <TableCell align={"center"}>
          {item.industry}
        </TableCell>
        <TableCell align={"center"}>
          {item.info}
        </TableCell>
        <TableCell align="center">
          {moment(item.createDate).format("DD/MM/YY HH:mm:ss")}
        </TableCell>
      </TableRow>
  )
};

export default CompanyElement;
