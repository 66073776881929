import {useEffect, useState} from "react";
import {
  Box,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CompanyElement from "../componenets/CompanyElement";
import {getCompanies} from "../services/CompanyService";
import AddIcon from '@mui/icons-material/Add';
import AddCompany from "../componenets/AddCompany";

const Companies = () => {

  const [items, setItems] = useState([])

  const [addCompanyState, setAddCompanyState] = useState(false);

  useEffect(() => {
    getCompanies().then(res => {
      setItems(res.data);
    }).catch(e => console.log(e));
  }, [addCompanyState]);


  const closeAddCompany = () => {
    setAddCompanyState(false);
  }

  const openAddCompany = () => {
    setAddCompanyState(true);
  }

  return (
      <>
        <AddCompany open={addCompanyState} handleClose={closeAddCompany}/>
        <Box sx={{marginTop: "24px", display: "flex", justifyContent: "space-between"}}>
      <Typography sx={{
        fontWeight: {md: 700, xs: "600"},
        fontSize: {md: "40px", xs: "28px"},
        lineHeight: {md: "52px", xs: "38px"}
      }} color="primary.main">Companies</Typography>
        <Box sx={{display: "flex", justifyContent: "right"}}>
          <Fab color="primary" aria-label="add" onClick={openAddCompany}>
            <AddIcon />
          </Fab>
        </Box>
        </Box>
        <Table sx={{marginTop: "25px", "&.MuiTable-root" : {
            borderCollapse: "separate",
            borderSpacing: "0 15px",
          }}}>
        <TableHead>
          <TableRow>
            <TableCell align={"center"}>
              Name
            </TableCell>
            <TableCell align={"center"}>
              Industry
            </TableCell>
            <TableCell align={"center"}>
              Info
            </TableCell>
            <TableCell align={"center"}>
              Create Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(function(item, i){
          return <CompanyElement item={item} key={i} />;
        })}
        </TableBody>
      </Table>
      </>
  )
};

export default Companies;
