import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';

const SchedulerSingleLine = ({handleEdit, handleDelete, schedulerData, dayOfWeek }) => {

  return (
      <>
      <Typography sx={{mt: "24px", fontWeight: {md: 700, xs: "600"},
        fontSize: {md: "36px", xs: "28px"},
        lineHeight: {md: "52px", xs: "38px"}}}>{dayOfWeek}</Typography>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell sx={{width: "40%"}} align={"left"}>Feature</TableCell>
        <TableCell sx={{width: "40%"}} align={"left"}>Day Of Week</TableCell>
        <TableCell sx={{width: "10%"}} align={"left"}>Hour</TableCell>
        <TableCell sx={{width: "10%"}} align={"right"}>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {schedulerData.map((data) => {
        return <TableRow>
          <TableCell sx={{width: "40%"}} align={"left"}>{data.featureType}</TableCell>
          <TableCell sx={{width: "40%"}} align={"left"}>{data.dayOfWeek}</TableCell>
          <TableCell sx={{width: "10%"}} align={"left"}>{data.hour}</TableCell>
          <TableCell sx={{width: "10%"}} align={"right"}>
            <DeleteOutlineIcon sx={{cursor: "pointer"}} onClick={() => handleDelete(data)}/>
            <EditIcon sx={{cursor: "pointer"}} onClick={() => handleEdit(data)}/>
          </TableCell>
        </TableRow>
      })}
    </TableBody>
  </Table>
      </>
  );
};

export default SchedulerSingleLine;
