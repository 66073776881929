import httpService from "./HttpService";

export async function uploadImages(images) {
  const token = localStorage.getItem("auth");
  return httpService.post(`image-template`, images, token);
}

export async function getImages(companyId, type) {
  const token = localStorage.getItem("auth");
  return httpService.get(`image-template/${companyId}?type=${type}`, null, token);
}

export async function deleteImage(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`image-template/${id}`, null, token);
}

export async function getImageTitles(companyId, type, available ) {
  const token = localStorage.getItem("auth");

  let url = `image-title-template/${companyId}?type=${type}`;

  if (available) {
    url += `&available=True`;
  }

  return httpService.get(url, null, token);
}

export async function deleteImageTitle(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`image-title-template/${id}`, null, token);
}

export async function saveImageTitle(imageTitle) {
  const token = localStorage.getItem("auth");
  return httpService.post(`image-title-template`, imageTitle, token);
}

export async function generateImageTitles(companyId, type) {
  const token = localStorage.getItem("auth");
  return httpService.put(`image-title-template/generate/${companyId}?type=${type}`, null, token);
}
