import {Box, Button, Paper, TextField, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import {useMessageContext} from "../contexts/MessageContext";
import {useState} from "react";
import {deleteArticleImage, publishArticle, saveArticle} from "../services/ArticleService";
import SelectNewImageDialog from "./feed/SelectNewImageDialog";

const ArticleSummery = ({setReload, element, isArticle, companyId}) => {
  const [editMode, setEditMode] = useState({
    open: true,
    article: element.article,
    intro: element.intro,
  });

  const {notificationHandler} = useMessageContext();

  const [changeImageOpen, setChangeImageOpen] = useState(false);

  const handlePublish = () => {
    publishArticle(element.id)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('Published successfully'))
        .catch(() => notificationHandler.error('Failed to publish'));
  };

  const handleImageDelete = () => {
    deleteArticleImage(element.id)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('Image deleted'))
        .catch(() => notificationHandler.error('Failed to delete image'))
  }

  const handleOnSave = () => {
    saveArticle({
      ...element, article: editMode.article, intro: editMode.intro
    }).then(r => element.article = editMode.article)
        .then(() => notificationHandler.success('Updated successfully'))
        .then(() => handleEditMode(element))

  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => notificationHandler.success('Text copied'))
  }

  const handleEditMode = (element) => {
    setEditMode(prv => {
      return {
        open: !prv.open,
        article: element.article
      }
    });
  }

  const updateSummery = (value) => {
    setEditMode(prv => {
      return {...prv, article : value}
    })
  }

  const updateIntro = (value) => {
    setEditMode(prv => {
      return {...prv, intro : value}
    })
  }

  return (<>
  <SelectNewImageDialog setReload={setReload} articleId={element.id} notificationHandler={notificationHandler} companyId={companyId} handleClose={() => setChangeImageOpen(false)} open={changeImageOpen} isArticle={isArticle}/>
  <Paper sx={{padding: "24px", mb: "12px"}} elevation={2}>
    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Typography sx ={{fontSize: "24px"}}>{element.title}</Typography>
      <Box >
        <ContentCopyIcon sx={{cursor: "pointer"}} color={"primary"} onClick={() => copyToClipboard(element.article)} />
        <EditIcon sx={{cursor: "pointer"}} color={"primary"} onClick={() => handleEditMode(element)}/>
      </Box>
    </Box>
      {element.articleImageUrl ? <>
          <Box sx ={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Box
              component="img"
              sx={{
                height: "50%",
                width: "50%",
                maxWidth: "300px",
                maxHeight: "500px"
              }}
              src={element.articleImageUrl}
          />
          </Box>
        <Box sx={{display: "flex", justifyContent: "end"}}>
        <Button onClick={handleImageDelete}>delete image</Button>
        </Box>
        </> : <>
      <Box sx={{display: "flex", justifyContent: "end"}}>
        <Button onClick={() => setChangeImageOpen(true)}>Add Image</Button>
      </Box>
      </>}
    {isArticle &&
        <TextField
            id="outlined-textarea"
            fullWidth
            label={"Intro"}
            sx={{fontSize: "16px", mt: "24px"}}
            value={editMode.intro}
            onChange={(e) => updateIntro(e.target.value)}
        />}

        <TextField
            label={"Article"}
            id="outlined-textarea"
            sx={{width: "100%", mt: "12px"}}
            value={editMode.article}
            onChange={(e) => updateSummery(e.target.value)}
            multiline
        />
          <Box sx={{display: "flex", justifyContent: "right"}}>
            <Button variant={"text"} sx={{mt: "12px"}} onClick={() => handlePublish()}>publish</Button>
            <Button variant={"contained"} sx={{mt: "12px"}} onClick={() => handleOnSave()}>Save</Button>
          </Box>
  </Paper>
  </>);
};

export default ArticleSummery;
