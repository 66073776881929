import {Box, Fab, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Loader from "./Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {useEffect, useRef, useState} from "react";
import {deleteBankImage, getBankImages, uploadBankImages} from "../services/ImageBankService";
import DownloadIcon from '@mui/icons-material/Download';

const ImageBank = ({company, type}) => {

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [reload, setReload] = useState(false);
  const inputFileRef = useRef( null );

  useEffect(() => {
    getBankImages(company.id, type)
        .then(res => setImages(res.data))
  }, [reload])

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  }

  const handleDownload = image => {
    fetch(image, {
      method: "GET",
      mode: 'cors',           // Ensures CORS is handled
      cache: 'no-cache',      // Prevents caching issues
      headers: {'Access-Control-Request-Headers': '*', 'Vary': 'Origin',},
    })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "image.png"); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onFileChange = async ( e ) => {
    setLoading(true);
    /*Selected files data can be collected here.*/
    let images = await Promise.all([...e.target.files].map(async file => {
      return await convertBase64(file)
    }));

    uploadBankImages({
      companyId: company.id,
      images: images,
      type: type
    }).then(() => setLoading(false))
        .then(() => setReload(prv => !prv))
  }

  const handleDelete = (id) => {
    deleteBankImage(id)
        .then(() => setReload(prv => !prv))
  };

  return (
      <Box>
        <Loader loading={loading}/>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={"left"}>
                Image
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {images.map(img => {
              return <TableRow>
                <TableCell align={"left"}>
                  <Box
                      component="img"
                      sx={{
                        height: 150,
                        width: 150,
                      }}
                      src={img.imageUrl}
                  />
                </TableCell>
                <TableCell align="right">
                  <Box sx={{display: "flex", justifyContent: "right", alignItems: "center"}}>
                  <Box sx={{cursor: "pointer"}} onClick={() => handleDelete(img.id)}>
                    <DeleteIcon/>
                  </Box>
                  <Box sx={{cursor: "pointer"}} onClick={() => handleDownload(img.imageUrl)}>
                    <DownloadIcon/>
                  </Box>
                  </Box>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
        <Box sx={{mt: "24px", display: "flex", justifyContent: "end"}}>
          <input
              type="file"
              hidden
              multiple
              accept="image/png, image/gif, image/jpeg"
              ref={inputFileRef}
              onChange={onFileChange}
          />

          <Fab color="primary" aria-label="add" onClick={onBtnClick}>
            <AddIcon />
          </Fab>

        </Box>
      </Box>
  )
};

export default ImageBank;
