import FeedSummery from "../FeedSummery";

const NewsPreview = ({data, item}) => {

  return (
      <>
      {data && data.data.sort((a, b) => a.id > b.id).map((element, i) => {
        return <FeedSummery key={i} element={element} i={i} newsId={item.entityId} companyId={item.companyId}/>
      })}
      </>
  );
};

export default NewsPreview;
