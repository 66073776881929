import {useEffect, useState} from "react";
import {getCompanyTitles, regenerateTitles} from "../../services/CompanyService";
import Loader from "../Loader";
import {Box, Button, Typography} from "@mui/material";
import CompanyTitleFormat from "./CompanyTitleFormat";
import {useMessageContext} from "../../contexts/MessageContext";

const CompanyTitles = ({company}) => {

  const [companyTitles, setCompanyTitles] = useState({});

  const [loader, setLoader] = useState(true);

  const {notificationHandler} = useMessageContext();

  const handelRegenerateTitles = () => {
    regenerateTitles(company.id)
        .then(_ => notificationHandler.success('Regenerated titles request sent'))
  };

  useEffect(() => {
    getCompanyTitles(company.id)
        .then(res => {
          let titlesDic = {};

          res.data.map((title) => {
            if (titlesDic[title.titleFormat] === undefined) {
              titlesDic[title.titleFormat] = [];
            }

            titlesDic[title.titleFormat].push(title);
          });

          setCompanyTitles(titlesDic);
          console.log(titlesDic);
          setLoader(false);
        });
  }, [company]);

  const getNumberOfTitles = () => {
    let sum = 0;

    Object.keys(companyTitles).map((key, index) => (
      sum += companyTitles[key].length
    ))

    return sum;
  }

  return (
      <>
        <Loader loading={loader}/>
        <Box>
          {companyTitles && <Typography>Number of Titles: {getNumberOfTitles()} </Typography>}
        </Box>
        <Box sx={{mt: "28px", mb: "28px"}}>
          {companyTitles && Object.keys(companyTitles).sort((a,b)=> a > b).map((key, index) => {
            return <CompanyTitleFormat companyId={company.id} format={key} companyTitles={companyTitles[key]} index={index}/>
          })}
        </Box>
        <Box sx={{display: "flex", alignItems: "right", justifyContent: "right"}}>
          <Button variant={"contained"} onClick={handelRegenerateTitles} >Regenerate titles</Button>
        </Box>
      </>
  );
};

export default CompanyTitles;
