import {Box} from "@mui/material";
import {useAuth} from "../contexts/AuthContext";
import {useState} from "react";
import {Navigate} from "react-router-dom";
import LoginForm from "../componenets/LoginForm";
import {restLogin} from "../services/AuthService";

const Login = () => {
    const {login, loggedIn} = useAuth();

    const [failedLogin, setFailedLogin] = useState(false);

    const handleRestLogin = (event) => {
      event.preventDefault();
      const { email, password } = document.forms[0];
      restLogin(email.value, password.value)
          .then(res => {
            const token = res.data;
            console.log(token);
            login(token)})
          .catch(e => {
            setFailedLogin(true);
          })
    }

  return  (
      <>
        {loggedIn != null && <Navigate to="/" replace />}
      <Box sx={{display: "flex", height: "100dvh"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
          <Box>
            <LoginForm handleRestLogin={handleRestLogin}
                       failedLogin={failedLogin} />
          </Box>
        </Box>
      </Box>
        </>
  );
};

export default Login;
