import {Box, Paper, Tab, Tabs, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import NewsPlayground from "../componenets/playgroundTabs/NewsPlayground";
import Loader from "../componenets/Loader";
import ArticlePlayground from "../componenets/playgroundTabs/ArticlePlayground";
import {getCompanies} from "../services/CompanyService";
import BrandingBannerPlayground from "../componenets/playgroundTabs/BrandingBannerPlayground";

const Playground = () => {

  const [currentTab, setCurrentTab] = useState(0);

  const [loading, setLoading] = useState(false);

  const [companies, setCompanies] = useState([]);


  useEffect(() => {
    getCompanies()
        .then(res => setCompanies(res.data))
  }, []);

  const handleChange = (event, val) => {
    setCurrentTab(val);
  };

  return (
      <>
        <Loader loading={loading}/>
        <Typography sx={{
          marginTop: "24px",
          fontWeight: {md: 700, xs: "600"},
          fontSize: {md: "40px", xs: "28px"},
          lineHeight: {md: "52px", xs: "38px"}
        }} color="primary.main">Playground</Typography>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mt: "28px"}}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="disabled tabs example">
          <Tab value={0} label={"News"}></Tab>
          <Tab value={1} label={"Weekly Digest"}></Tab>
          <Tab value={2} label={"Articles"}></Tab>
          <Tab value={3} label={"Single Post"}></Tab>
          <Tab value={4} label={"Branding Banner"}></Tab>
        </Tabs>
        </Box>

        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mt: "28px"}}>
          <Paper sx={{width: "80%", mt: "24px"}}>
            {currentTab === 0 && <NewsPlayground type={"NEWS"} setLoading={setLoading} companies={companies}/>}
            {currentTab === 1 && <NewsPlayground type={"WEEKLY_DIGEST"} setLoading={setLoading} companies={companies}/>}
            {currentTab === 2 && <ArticlePlayground type={"ARTICLE"} setLoading={setLoading} companies={companies}/>}
            {currentTab === 3 && <ArticlePlayground type={"SINGLE_POST"} setLoading={setLoading} companies={companies}/>}
            {currentTab === 4 && <BrandingBannerPlayground setLoading={setLoading} companies={companies}/>}
          </Paper>
        </Box>
        </>
  )
};

export default Playground;
