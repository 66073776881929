import {Outlet} from 'react-router-dom';
import {AuthGuard} from "../guards/AuthGuard";
import SideNav from "../componenets/nav/SideNav";
import {Box} from "@mui/material";
import {useState} from "react";
import {MenuProvider} from "../contexts/MenuContext";

const DashboardLayout = () => {
  const [isMiniNav, setIsMiniNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const changeIsMenuOpen = () => {
    setIsMenuOpen(prv => {
      return !prv;
    })
  };

  const changeMiniNavDir = () => {
    setIsMiniNav(prv => {
      return !prv;
    })
  }

  return (
      <AuthGuard>
        <MenuProvider>
          <SideNav changeIsMenuOpen={changeIsMenuOpen} isMiniNav={isMiniNav} changeMiniNavDir={changeMiniNavDir} isMenuOpen={isMenuOpen}/>
          <Box sx={{paddingLeft: "17%", paddingRight: "5%", marginTop: {xs: "65px", md: '0'}}}>
            <Outlet />
          </Box>
        </MenuProvider>
      </AuthGuard>
  );
};

export default DashboardLayout;
