import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs
} from "@mui/material";
import {createCompany} from "../services/CompanyService";
import {useState} from "react";
import {useMessageContext} from "../contexts/MessageContext";
import CompanyCreateInfoPage from "./company/createCompanyTabs/CompanyCreateInfoPage";
import CompanyCreateCustomerTab from "./company/createCompanyTabs/CompanyCreateCustomerTab";
import CompanyCreateKeywordsTag from "./company/createCompanyTabs/CompanyCreateKeywordsTag";
import CompanyCreateAboutTab from "./company/createCompanyTabs/CompanyCreateAboutTab";
import CompanyCreateSettingsTab from "./company/createCompanyTabs/CompanyCreateSettingsTab";

const AddCompany = ({open, handleClose}) => {

  const {notificationHandler} = useMessageContext();

  const [currentTab, setCurrentTab] = useState(0);

  const [company, setCompany] = useState({});

  const handleChange = (event, val) => {
    setCurrentTab(val);
  };

  const setField = (field, val) => {
    setCompany(prv => {
      return {
        ...prv,
        [field]: val
      }
    })
  }

  const setImageFields = (filed, val, type) => {
    setCompany(prv => {
      let oldType = prv[type] || {};
      return {
        ...prv,
        [type]: {...oldType, [filed]: val}
      }
    })
  }

  return (
  <>
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            console.log(company);
            createCompany(company).then(r => handleClose())
                .then(() => notificationHandler.success('Company Saved'))
          },
        }}
    >
  <DialogTitle>Create Company</DialogTitle>
  <DialogContent>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Tabs value={currentTab} onChange={handleChange} >
          <Tab sx={{textTransform: "none"}} label="Profile" />
          <Tab sx={{textTransform: "none"}} label="Customers and Competitors" />
          <Tab sx={{textTransform: "none"}} label="Product" />
          <Tab sx={{textTransform: "none"}} label="Keywords" />
          <Tab sx={{textTransform: "none"}} label="About" />
          <Tab sx={{textTransform: "none"}} label="Settings" />
        </Tabs>
      </Box>
      {currentTab === 0 && <CompanyCreateInfoPage setField={setField} company={company}/>}
      {currentTab === 1 && <CompanyCreateCustomerTab  setField={setField} company={company} />}
      {/*{currentTab === 2 && <CompanyProductTab setField={setCompany} company={company} />}*/}
      {currentTab === 3 && <CompanyCreateKeywordsTag setField={setField} company={company} />}
      {currentTab === 4 && <CompanyCreateAboutTab setField={setField} company={company} />}
      {currentTab === 5 && <CompanyCreateSettingsTab setImageFields={setImageFields} setField={setField} company={company} />}
  </DialogContent>
  <DialogActions>
    <Button color={"error"} onClick={handleClose}>Cancel</Button>
    <Button variant={"contained"} type="submit">Create</Button>
  </DialogActions>
</Dialog>
</>
)
};

export default AddCompany;
