import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select
} from "@mui/material";
import {useState, useEffect} from "react";
import {saveScheduler} from "../services/SchedulerService";
import Loader from "./Loader";

const SchedulerNewDialog = ({open, handleClose, company, oldData}) => {

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    dayOfWeek: oldData?.dayOfWeek != null ? oldData.dayOfWeek : "SUNDAY",
    hour: oldData?.hour  != null ? oldData.hour : 6,
    featureType: oldData?.featureType  != null ? oldData.featureType : "NEWS",
    companyId: company.id,
    id: oldData?.id  != null ? oldData.id : null
  });

  useEffect(() => {
    setData({
      dayOfWeek: oldData?.dayOfWeek != null ? oldData.dayOfWeek : "SUNDAY",
      hour: oldData?.hour  != null ? oldData.hour : 6,
      featureType: oldData?.featureType  != null ? oldData.featureType : "NEWS",
      companyId: company.id,
      id: oldData?.id  != null ? oldData.id : null
    });
  },[oldData]);

  return (
      <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              setLoading(true);
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              saveScheduler(data)
                  .then(r => handleClose())
                  .then(() => console.log('Scheduler Saved'))
                  .finally(() => setLoading(false));
              console.log(formJson);
            },
          }}
      >
        <DialogTitle>Create Entry</DialogTitle>
        <DialogContent>
          <Loader loading={loading}/>
          <FormControl sx={{width: "240px", mt: "8px"}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant={"standard"}
                value={data.dayOfWeek}
                onChange={e => setData(prv => {
                  return {
                    ...prv,
                    dayOfWeek: e.target.value
                  }
                })}
            >

              <MenuItem value="SUNDAY">Sunday</MenuItem>
              <MenuItem value="MONDAY">Monday</MenuItem>
              <MenuItem value="TUESDAY">Tuesday</MenuItem>
              <MenuItem value="WEDNESDAY">Wednesday</MenuItem>
              <MenuItem value="THURSDAY">Thursday</MenuItem>
              <MenuItem value="FRIDAY">Friday</MenuItem>
              <MenuItem value="SATURDAY">Saturday</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <FormControl sx={{width: "240px", mt: "8px"}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant={"standard"}
                value={data.hour}
                onChange={e => setData(prv => {
                  return {
                    ...prv,
                    hour: e.target.value
                  }
                })}
            >

              {Array.from(Array(24).keys()).map((i) => {
                return <MenuItem value={i}>{i < 10 ? `0${i}:00` : `${i}:00`} (GMT+3: {i < 10 ? `${i + 3}:00` : `${i + 3}:00`})</MenuItem>
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <FormControl sx={{width: "240px", mt: "8px"}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant={"standard"}
                value={data.featureType}
                onChange={e => setData(prv => {
                  return {
                    ...prv,
                    featureType: e.target.value
                  }
                })}
            >

              <MenuItem value="NEWS">News</MenuItem>
              <MenuItem value="WEEKLY_DIGEST">Weekly Digest</MenuItem>
              <MenuItem value="NEWS_GENERAL">News General</MenuItem>
              <MenuItem value="NEWS_FINANCE">News Finance</MenuItem>
              <MenuItem value="SINGLE_POST">Single Post</MenuItem>
              <MenuItem value="ARTICLE">Article</MenuItem>
              <MenuItem value="BRANDING_BANNER">Branding Banner</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color={"error"} onClick={handleClose}>Cancel</Button>
          <Button variant={"contained"} type="submit">Save</Button>
        </DialogActions>
      </Dialog>
  );
};

export default SchedulerNewDialog;
