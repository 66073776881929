import httpService from "./HttpService";

export async function manualNews(companyId, days, maxResult, type) {
  const token = localStorage.getItem("auth");
  return httpService.put(`news`, {
    companyId,
    newsType: type,
    maxResults : maxResult,
    days,
  }, token);
}

export async function getNews(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`news/${id}`, null, token);
}

export async function fullManualNews(body) {
  const token = localStorage.getItem("auth");
  return httpService.put(`news`, body, token);
}

export async function saveNews(feed) {
  const token = localStorage.getItem("auth");
  return httpService.post(`news/data`, feed, token);
}

export async function publishNews(newsId, newsDataId, companyId) {
  const token = localStorage.getItem("auth");
  return httpService.put(`news/publish`, {
    newsId,
    newsDataId,
    companyId
  }, token);
}
